import React from 'react'
import * as myStyles from './styles.module.scss'
import HeroModule from '../../lib/components/modules/hero'
import FixedTitle from '../../lib/components/components/fixedTitle'

import Testimonials from '../../lib/components/modules/testimonials'
import {
    heroProps,
    testimonials,
    featuresTitle,
    preFooterTitle,
    graphicsAndLogos,
    graphicLogosTextBlocks,
    cardsDataPrefooter,
} from './constants'
import PreFooter from '../../lib/components/modules/prefooter'
import GraphicLogos from '../../lib/components/modules/graphic-logos'
import backgroundImageMobile from '../../../resources/images/hanzo/product/background_mobile.webp'
import backgroundImage from '../../../resources/images/hanzo/product/background.webp'
import useResponsive from '../../lib/hooks/useResponsive'
import { BREAKPOINTS } from '../../lib/constants/breakpoints'

const ProductPage = () => {
    const styles: any = myStyles
    const isMobile = useResponsive({ threshold: BREAKPOINTS.md })

    return (
        <div className={styles.product_page}>
            <div className={styles.content}>
                <HeroModule
                    {...heroProps}
                    background={{
                        image: {
                            src: isMobile ? backgroundImageMobile : backgroundImage,
                            alt: 'pattern',
                        },
                    }}
                />
                <FixedTitle title={featuresTitle} />
                {graphicLogosTextBlocks.map((textBlock, index) => {
                    return <GraphicLogos key={index} {...textBlock} />
                })}

                <div className={styles.bg_grey_black}>
                    <GraphicLogos {...graphicsAndLogos} />
                </div>
                <div className={styles.bg_grey_black}>
                    <Testimonials testimonials={testimonials} />
                </div>
                <div className={styles.bg_grey_20}>
                    <PreFooter title={preFooterTitle} cardsData={cardsDataPrefooter} />
                </div>
            </div>
        </div>
    )
}

export default ProductPage
